import styled from 'styled-components';
import { breakpoint } from 'ui/elements/ThemeProvider/utils';

export const ProgramsIntro = styled.div`
  text-align: center;
`;

export const NavBox = styled.div`
  color: ${(props) => props.theme.palette.white};
  border: 1px solid ${(props) => props.theme.palette[props.color]};
  background: ${(props) => props.theme.palette[props.color]};
  padding: 30px 25px 30px 15px;
  display: flex;
  align-items: center;
  text-align: left;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  margin: 10px 0;
  
  ${breakpoint.lg`
    margin: 0;
  `}
  
  &:hover {
    background: ${(props) => props.theme.palette.white};
    color: ${(props) => props.theme.palette[props.color]};  
  }
  
  header {
    padding-left: 20px;
  }
  
  .h1 {
    display: block;
    font-size: 2.2rem;
    font-weight: 700;
    margin: 0;
  }
  
  .h5 {
    display: block;
    font-size: 1.4rem;
    font-weight: 500;
    text-transform: uppercase;
    margin: 0;
  }
`;
