import React, { useState } from 'react';
import PropTypes from 'prop-types';

import * as S from './styles';

import Nav from './Nav';

const TabsSlider = ({
  children,
}) => {
  const [active, setActive] = useState(0);
  const items = React.Children.map(children, ({ props }) => ({
    label: props.label,
    image: props.image,
  }));

  return (
    <S.Tabs>
      <Nav
        active={active}
        onTabClick={setActive}
        items={items}
      />
      <S.Content>
        <S.ContentInner active={active}>
          {children}
        </S.ContentInner>
      </S.Content>
    </S.Tabs>
  );
};

TabsSlider.propTypes = {
  children: PropTypes.any,
};

export default TabsSlider;
