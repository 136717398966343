import React from 'react'
import { graphql } from 'gatsby';
import _get from 'lodash/get';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import withLocalization from 'decorators/withLocalization';
import { Section } from 'components/Layout/styles';
import PageLayout from 'components/Layout/PageLayout';
import ContentBox from 'widgets/ContentBox';

import ProgramsSummary from 'modules/Homepage/ProgramsSummary'
import ProgramsIntro from 'modules/Homepage/ProgramsIntro'
import Reasons from 'modules/Homepage/Reasons'
import HeroBackground from 'modules/Homepage/HeroBackground'

const Homepage = ({
  data: rawData,
  location,
}) => {
  const data = _get(rawData, 'sanityIndex');
  const { i18n } = useTranslation();
  const heroProps = {
    type: 'corner',
    variant: 'left-right',
    em: 'Excellence in Management Education',
    background: (<HeroBackground />)
  }

  return (
    <PageLayout data={data} heroProps={heroProps}>
      <h1 className="hidden">{data.hero.title.localized}</h1>
      <GatsbySeo
        title='WHU – Otto Beisheim School of Management'
        openGraph={{
          title: 'Homepage',
          url: location.href,
          locale: i18n.language,
        }}
      />
      <Section>
        <ProgramsIntro />
      </Section>

      <Section>
        <ProgramsSummary />
      </Section>

      <Section>
        <Reasons />
      </Section>

      <Section>
        <ContentBox blocks={data._rawContent} />
      </Section>
    </PageLayout>
  )
};

export const query = graphql`
  query Homepage($id: String, $language: String!) {
    sanityIndex(id: { eq: $id }) {
      hero {
        ...HeroFragment
      }
      meta {
        title {
          de
          en
        }
        description {
          de
          en
        }
      }
      _rawContent(resolveReferences: { maxDepth: 10 })
    }
    locales: allLocale(filter: { language: {eq: $language} }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default withLocalization(Homepage);
