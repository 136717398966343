import styled from 'styled-components';
import { breakpoint } from '../../ThemeProvider';

import * as Shared from '../styles';
import { TabPanel } from '../styles';

const CARET_WIDTH = 50;
const CARET_HEIGHT = 20;

const GAP = 16;

export const NavItem = styled(Shared.NavItemDummy)`
  padding: 35px 15px;
`;

export const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${NavItem} {
    flex: 0 0 ${(props) => `calc(100% / ${props.length} - ${GAP}px)`};  
  }
  
  ${breakpoint.lg`
    justify-content: space-between;
    flex-direction: row;
    margin: 0;
  `}
`;

export const TrackerIndicator = styled.span`
  display: block;
  transition: transform 0.5s;
  width: ${CARET_HEIGHT}px;
  height: ${CARET_WIDTH}px;
  will-change: auto;
  
  &:after,
  &:before {
    content: '';
    top: 0;
    left: 0;
    right: 0;
    left: 0;
    bottom: 0;
    position: absolute;
    margin: auto;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: ${CARET_WIDTH / 2}px ${CARET_HEIGHT}px ${CARET_WIDTH / 2}px 0;
  }

  &:after {
    border-color: transparent ${(props) => props.theme.palette.primary} transparent transparent;  
  }
  
  &:before {
    border-color: transparent ${(props) => props.theme.palette.white} transparent transparent;
    z-index: 2;
    left: 1px; 
  }

  ${breakpoint.lg`
    width: ${CARET_WIDTH}px;
    height: ${CARET_HEIGHT}px;
    
    &:after,
    &:before {
      border-width: 0 ${CARET_WIDTH / 2}px ${CARET_HEIGHT}px ${CARET_WIDTH / 2}px;
    }
    
    &:after {
      border-color: transparent transparent ${(props) => props.theme.palette.primary} transparent;
    }
    
    &:before {
      border-color: transparent transparent ${(props) => props.theme.palette.white} transparent;
      top: 2px;
      left: 0;
    }
  `}
  
`;

export const Tracker = styled.div`
  position: relative;
  z-index: 2;

  ${TrackerIndicator} {
    height: ${(props) => `calc(100% / ${props.length} - ${GAP}px)`};
    transform: ${(props) => {
      const space = (props.length * GAP) / (props.length - 1);
      return `translateY(calc(${100 * props.active}% + ${space * props.active}px))`
    }};
    
    ${breakpoint.lg`
      width: ${(props) => `calc(100% / ${props.length} - ${GAP}px)`};
      height: 100%;
      transform: ${(props) => {
        const space = (props.length * GAP) / (props.length - 1);
        return `translateX(calc(${100 * props.active}% + ${space * props.active}px))`
      }};
    `}  
  }
`;


export const NavContainer = styled.div`
  display: flex;
  
  ${Nav} {
    flex: 1 1 auto;
  }
  
  ${Tracker} {
    margin: 0 0 0 8px;

    ${breakpoint.lg`
      margin: 8px 0 0 0;
      height: ${CARET_HEIGHT}px
    `}
    
  }
  
  ${breakpoint.lg`
    flex-direction: column;
  `}
`;


export const Content = styled.div`
  position:relative;
  border: 1px solid ${(props) => props.theme.palette.primary};
  overflow: hidden;
`;

export const ContentInner = styled.div`
  display: flex;

  ${TabPanel} {
    transform: translateX(${(props) => `${-props.active * 100}%`})
  }
`;

export const Tabs = styled.div`
  display: flex;

  ${breakpoint.lg`
    flex-direction: column;
  `}

  ${NavContainer}, ${Content} {
    flex: 0 0 50%;
  }
`;
