import React from 'react';
import { Row, Col } from 'react-grid-system';
import Icon from 'ui/elements/Icon';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import * as S from './styles';
import { useNavigation } from 'widgets/Link';

const ProgramsIntro = () => {
  const { t } = useTranslation('homepage');
  const navigation = useNavigation();

  return (
    <S.ProgramsIntro>
      <Row justify='center'>
        <Col xs={12} lg={5}>
          <S.NavBox color='secondaryAlpha' onClick={() => navigation('/open-programs')}>
            <Icon size={70} name='open-programs' />
            <header>
              <span className="h1">{t('intro.openPrograms.title')}</span>
              <span className="h5">{t('intro.openPrograms.subtitle')}</span>
            </header>
          </S.NavBox>
        </Col>
        <Col xs={12} lg={5}>
          <S.NavBox color='otherAlpha' onClick={() => navigation('/customized-programs-for-companies/')}>
            <Icon size={70} name='customized-programs' />
            <header>
              <span className="h1">{t('intro.customizedPrograms.title')}</span>
              <span className="h5">{t('intro.customizedPrograms.subtitle')}</span>
            </header>
          </S.NavBox>
        </Col>
      </Row>
      <Row justify='center'>
        <Col xs={12} md={8}>
          <p>{t('intro.description')}</p>
        </Col>
      </Row>
    </S.ProgramsIntro>
  )
};

export default ProgramsIntro;
