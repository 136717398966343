import React from 'react';
import Button from 'ui/elements/Button';
import OpticalHeading from 'ui/elements/Heading/OpticalHeading';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import Image from 'widgets/Image';
import ContentBox from 'widgets/ContentBox';
import Link from 'widgets/Link';
import { slugify } from 'utils/helpers';

import { palette } from 'modules/OpenPrograms/utils';

import * as S from './styles';
import { graphql } from 'gatsby'
import { ProgramEntryFragment } from '../../../generated/graphql-operations'

const Entry: React.FC<{
  program: ProgramEntryFragment
}> = ({
  program,
}) => {
  const { t } = useTranslation('common');
  const hero = program?.hero;
  const to = slugify(program.title);

  return (
    <S.ProgramEntry key={program.id}>
      <S.ProgramEntryHeader>
        {
          hero && (
            <Image
              gatsbyImageData={hero.gatsbyImageData}
              alt={program.title ?? ""}
            />
          )
        }
        <OpticalHeading renderAs="h2" level={6} color={palette.variant}>
          {program.title ?? ""}
        </OpticalHeading>
      </S.ProgramEntryHeader>

      {
        program._rawIntro && (
          <ContentBox
            blocks={program._rawIntro[0]}
            truncateLines={4}
          />
        )
      }

      <S.ProgramEntryFooter>
        <Button
          to={`/open-programs/${to}`}
          variant='outlined'
          color="secondaryAlpha"
          as={Link}
        >
          {t('findOutMore')}
        </Button>
      </S.ProgramEntryFooter>
    </S.ProgramEntry>
  )
};

export default Entry;

export const query = graphql`
    fragment ProgramEntry on SanityProgram {
      id
      title
      _rawIntro(resolveReferences: { maxDepth: 1 })
      category {
        id
      }
      hero {
        gatsbyImageData(
          width: 250
          height: 160
          layout: FIXED
          fit: CROP
          placeholder: DOMINANT_COLOR
        )
      }
    }
`
