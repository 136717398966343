import styled from 'styled-components';

export const Header = styled.header`
  text-align: center;
  text-transform:uppercase;
  margin-bottom: -20px;
  
  .h1 {
    font-size: 4rem;
    font-weight: 400;
    margin: 0;
  }
  
`;
