import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styles';

const TabPanel = ({
  children,
}) => {
  return (
    <S.TabPanel>
      {children}
    </S.TabPanel>
  )
};

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TabPanel;
