import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styles';
import { NavItemActive } from '../styles';

const Nav = ({
  items,
  active,
  onTabClick,
}) => {
  return (
    <S.NavContainer>
      <S.Nav length={items.length}>
        {
          items.map ((item, index) => (
            <S.NavItem
              length={items.length}
              as={(active === index) && NavItemActive}
              key={index}
              image={item.image}
              onClick={(ev) => {
                ev.preventDefault();
                onTabClick(index)
              }}
            >
              <span>{item.label}</span>
            </S.NavItem>
          ))
        }
      </S.Nav>
      <S.Tracker active={active} length={items.length}>
        <S.TrackerIndicator />
      </S.Tracker>
    </S.NavContainer>
  )
};

Nav.propTypes = {
  items: PropTypes.arrayOf (
    PropTypes.shape ({
      label: PropTypes.string.isRequired,
      image: PropTypes.string,
    }),
  ).isRequired,
  active: PropTypes.number.isRequired,
  onTabClick: PropTypes.func.isRequired,
};

Nav.defaultProps = {
  onTabClick: () => null,
};

export default Nav;
