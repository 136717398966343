import React, { useMemo, useRef } from 'react'
import { useScreenClass } from 'react-grid-system'
import { useIsSSR } from 'utils/isSSR'

import TabsCollapse from './TabsCollapse'
import TabsSlide from './TabsSlide'

const Tabs = ({
  children,
}) => {
  const ref = useRef(null)
  const screenClass = useScreenClass(ref);
  const isSSR = useIsSSR()

  const isDesktop = useMemo(() => {
    return ['xs', 'sm'].indexOf(screenClass) !== -1
  }, [screenClass])

  if (isSSR) {
    return null;
  }

  return <div ref={ref}>
    {isDesktop && (
      <TabsCollapse>
        {children}
      </TabsCollapse>
    )}
    {!isDesktop && (
      <TabsSlide>
        {children}
      </TabsSlide>
    )}
  </div>
}

export default Tabs;
