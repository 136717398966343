import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby'
import { HeroBackgroundQuery } from 'src/generated/graphql-operations';
import { useIsSSR } from 'utils/isSSR'

const query = graphql`
  query HeroBackground {
    file(relativePath: {eq: "homepage-hero.mp4"}) {
      id
      childVideoFfmpeg {
        webm: transcode(codec: "libvpx-vp9", maxWidth: 1920, maxHeight: 600, fileExtension: "webm", outputOptions: ["-crf 20", "-b:v 0"]) {
          width
          src
          presentationMaxWidth
          presentationMaxHeight
          originalName
          height
          aspectRatio
        }
        mp4: transcode(codec: "libx264", maxWidth: 1920, maxHeight: 600, fileExtension: "mp4", options:[["-profile:v", "main"], ["-pix_fmt", "yuv420p"]], outputOptions: ["-movflags faststart"]) {
          width
          src
          presentationMaxWidth
          presentationMaxHeight
          originalName
          height
          aspectRatio
        }
      }
    }
  }
`

const SHero = styled.div`
  position: relative;
  height: 100%;
  
  video {
    position: absolute;
    
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    
    width: 100%;
    height: 100%;
    
    object-fit: cover;
    object-position: center;
  }
`;

const Hero: React.FC = () => {
  const { file } = useStaticQuery<HeroBackgroundQuery>(query)
  const ref = useRef<IntersectionObserver>()
  const videoRef = useRef<HTMLVideoElement>(null)
  const [loaded, setLoaded] = useState(false)
  const [videoActive, setVideoActive] = useState(false)
  const isSSR = useIsSSR()

  const handleRef = useCallback((elem: HTMLDivElement | null) => {
    if (ref.current) {
      ref.current?.disconnect()
    }

    if (loaded || !elem || !("IntersectionObserver" in window)) {
      return
    }

    ref.current = new IntersectionObserver(function (entries) {
      for (const entry of entries) {
        if (!entry.isIntersecting) {
          continue
        }

        setLoaded(true)
      }
    })

    ref.current?.observe(elem)
  }, [loaded])

  const videos = useMemo(() => {
    if (!file) {
      return []
    }

    return [
      {
        id: `${file.id}-webm`,
        src: file.childVideoFfmpeg?.webm?.src ?? undefined,
        type: "video/webm"
      },
      {
        id: `${file.id}-mp4`,
        src: file.childVideoFfmpeg?.mp4?.src ?? undefined,
        type: "video/mp4"
      }
    ].filter(({ src }) => !!src)
  }, [file])

  useEffect(() => {
    if (isSSR) {
      return
    }

    const checkWindowSize = () => {
      setVideoActive(a => a || window.innerWidth > 764);
    }

    window.addEventListener("resize", checkWindowSize)
    checkWindowSize()

    return () => {
      window.removeEventListener("resize", checkWindowSize)
    }
  }, [isSSR])

  useLayoutEffect(() => {
    if (!loaded) {
      return
    }

    const f = requestAnimationFrame(() => videoRef.current?.load())

    return () => {
      cancelAnimationFrame(f)
    }
  }, [loaded])

  return (
    <SHero ref={handleRef}>
      {videoActive && <video ref={videoRef} autoPlay muted loop playsInline>
        {videos.map(video => <source
          key={video.id}
          src={loaded ? video.src : undefined}
          type={video.type}
        />)}
      </video>}
    </SHero>
  )
};

export default Hero;

