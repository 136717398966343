import React from 'react';
import { Container } from 'react-grid-system';
import OpticalHeading from 'ui/elements/Heading/OpticalHeading';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import * as S from './styles';

const Reasons = () => {
  const { t } = useTranslation('homepage');

  return (
    <Container fluid>
      <S.Header>
        <OpticalHeading renderAs="h2" level={6} color='shade'>
          {t('reasons.whyWHU')}
        </OpticalHeading>
        <span className="h1">
          {t('reasons.whuExecutive')}<br />
          {t('reasons.educationInNumbers')}
        </span>
      </S.Header>
    </Container>
  )
};

export default Reasons;
