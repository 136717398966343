import React from 'react';
import { useSpring } from 'react-spring';

import useMeasure from '../../../hooks/useMeasure';

import * as S from './styles';

const Box = ({
  children,
  label,
  active,
  onToggle,
}) => {
  const [ref, { height }] = useMeasure();
  const props = useSpring({
    height: active ? `${height}px` : `0px`,
  });

  return (
    <div>
      <S.NavItem onClick={onToggle}>
        <span>{label}</span>
      </S.NavItem>
      <S.Content style={props}>
        <div ref={ref}>
          {children}
        </div>
      </S.Content>
    </div>
  )
};

export default React.memo(Box);
