import { useRef, useEffect, useState } from 'react';

const getElDimensions = (el) => {
  if (!el) {
    return;
  }

  const { left, top, width, height } = el.getBoundingClientRect();

  return { left, top, width, height };
};

const useMeasure = () => {
  const ref = useRef(null);
  const [dimensions, setDimensions] = useState({});

  useEffect(() => {
    if (!ref || !ref.current) {
      return;
    }

    const measure = () => window.requestAnimationFrame(() => {
      const data = getElDimensions(ref.current);
      setDimensions(data);
    });

    measure();
    window.addEventListener('resize', measure);

    return () => {
      window.removeEventListener('resize', measure);
    }

  }, [ref]);

  return [ref, dimensions];
}

export default useMeasure
