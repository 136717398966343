import styled, { css } from 'styled-components';

import { breakpoint } from '../ThemeProvider';

const gradient = css`
  background-image: linear-gradient(
    to left,
    ${(props) => props.theme.palette.secondaryBeta},
    ${(props) => props.theme.palette.secondaryAlpha}
  );
`;


export const NavItemDummy = styled.a`
  position: relative;
  color: ${(props) => props.theme.palette.white};
  background-color: ${(props) => props.theme.palette.secondaryAlpha};
  cursor: pointer;
  display: block;
  text-align: center;
  transition: all 0.5s;
  
  span {
    position: relative;
    z-index: 3;
  }
  
  &:before,
  &:after {
    opacity: 0;
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;  
  }
  
  &:after {
    z-index: 2;
    ${gradient}
  }
  
  &:before {
    z-index: 1;
    background-image: ${(props) => (props.image ? `url(${props.image})` :  'none')};
    background-repeat: no-repeat;
    background-size: cover;
    transition: opacity 0.5s;
  }
  
  &:hover {
    &:before, &:after {
      opacity: 0.5;
    }
  }
`;

export const NavItemActive = styled(NavItemDummy)`
  ${gradient}
  
  &:before, &:after {
    opacity: 0.5;
  };
`;

export const TabPanel = styled.div`
  padding: 15px;
  flex: 0 0 100%;
  overflow: hidden;
  transition: transform 0.5s;
  
  ${breakpoint.lg`
    padding: 35px;
  `}
  
`;
