import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Box from './Box';
import * as S from './styles';

const TabsAccordion = ({
  children,
}) => {
  const [active, setActive] = useState(-1);

  return (
    <div>
      <S.Content>
        {
          React.Children.map(children, (child, index) => (
            <Box
              key={index}
              active={active === index}
              label={child.props.label}
              onToggle={() => setActive(index)}
            >
              {child}
            </Box>
          ))
        }
      </S.Content>
    </div>
  );
};

TabsAccordion.propTypes = {
  children: PropTypes.any,
};

export default TabsAccordion;
