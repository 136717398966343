import styled from 'styled-components';
import { Heading } from 'ui/elements/Heading/styles';

export const ProgramEntry = styled.div`
  position: relative;
  padding-bottom: 50px;
  height: 100%;
  
  ${Heading} {
    margin: 15px 0;
  }
`;

export const ProgramEntryHeader = styled.header`
  margin-bottom: 15px;
`;

export const ProgramEntryFooter = styled.footer`
  position: absolute;
  bottom: 0;
`;
