import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { buildCategoryProgramMap } from 'modules/OpenPrograms/utils';
import { flattenResponse } from 'utils/helpers';
import { useLocalize } from 'utils/localize';

import Entry from './Entry';
import { TabPanel, Tabs } from 'ui/elements/Tabs'
import Carousel from 'ui/elements/Carousel'

const ReadProgramsSummary = graphql`
  query ReadProgramsSummary {
    allSanityCategories(sort: { fields: title }) {
      edges {
        node {
          title
          id
        }
      }
    }

    allSanityProgram {
      edges {
        node {
          ...ProgramEntry
        }
      }
    }
  }
`;

const ProgramsSummary = () => {
  const data = useStaticQuery(ReadProgramsSummary);

  const categories = flattenResponse(data, 'allSanityCategories');
  const programs = useLocalize(
    flattenResponse(data, 'allSanityProgram')
  );
  const entries = buildCategoryProgramMap(categories, programs);

  return (
    <Tabs>
      {
        entries.map((entry) => (
          <TabPanel label={entry.title} key={entry.id}>
            <Carousel responsive={{
              xs: { items: 1 },
              sm: { items: 2 },
              md: { items: 2 },
              lg: { items: 2 },
            }}>
              {
                entry.programs.map((program) => (
                  <Entry key={program.id} program={program} />
                ))
              }
            </Carousel>
          </TabPanel>
        ))
      }
    </Tabs>
  )
};

export default ProgramsSummary;
