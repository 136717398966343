import styled from 'styled-components';
import { animated } from 'react-spring';

import * as Shared from '../styles';

export const NavItem = styled(Shared.NavItemDummy)`
  margin-bottom: 10px;
  padding: 30px 15px;
`

export const Content = styled(animated.div)`
  overflow: hidden;
`;
